import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SessionModel } from '@backend/api/session/session.model';
import { catchError } from 'rxjs/operators';
import { BadRequest } from '@core/http/models/bad-request';
import { throwError } from 'rxjs';
import { LoginFailedError } from '@backend/api/session/session-api.errors';

@Injectable({
  providedIn: 'root',
})
export class SessionApiService {
  private readonly httpClient = inject(HttpClient);

  login(login: string, password: string) {
    return this.httpClient
      .post<SessionModel>('session/$login', {
        login,
        password,
      })
      .pipe(
        catchError((err) => {
          if (err instanceof BadRequest) {
            return throwError(() => new LoginFailedError(err.error.message));
          }

          return throwError(() => err);
        }),
      );
  }

  logout() {
    return this.httpClient.post<SessionModel>('session/$logout', {});
  }

  getCurrent() {
    return this.httpClient.get<SessionModel>('session');
  }
}
