import { Component } from '@angular/core';
import { SessionQuery } from '@core/session/store/session.query';

@Component({
  selector: 'rais-user-modal',
  templateUrl: './user-modal.component.html',
  styleUrls: ['./user-modal.component.less'],
})
export class UserModalComponent {
  constructor(public sessionQuery: SessionQuery) {}
}
