<div style="display: flex">
  <img src="../../../assets/img/app_logo.svg" style="margin-left: auto; margin-right: auto;" />
</div>

<form nzLayout="vertical" nz-form class="login--form" [formGroup]="form" (keydown.enter)="onSubmit()">
  <nz-form-item [nzGutter]="[8, 8]">
    <nz-form-label> Имя пользователя </nz-form-label>
    <nz-form-control>
      <rais-text-input formControlName="login"></rais-text-input>
    </nz-form-control>
  </nz-form-item>
  <nz-form-item [nzGutter]="[8, 8]">
    <nz-form-label> Пароль </nz-form-label>
    <nz-form-control>
      <rais-input-wrapper>
        <input id="password" type="password" nz-input formControlName="password" />
      </rais-input-wrapper>
    </nz-form-control>
  </nz-form-item>
</form>
<div class="modal-footer" *nzModalFooter>
  <button nz-button class="btn--flat" nzType="primary" [nzLoading]="isLoggingIn()" [disabled]="form.invalid" (click)="onSubmit()">
    Войти
  </button>
</div>
