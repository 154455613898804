import { AfterViewInit, ChangeDetectionStrategy, Component, inject, OnDestroy, signal } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NZ_MODAL_DATA, NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { SessionService } from '@core/session/store/session.service';
import { User } from '@core/session/models/user';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { SessionQuery } from '@core/session/store/session.query';
import { UntilDestroy } from '@ngneat/until-destroy';
import { finalize } from 'rxjs/operators';
import { LoginFailedError } from '@backend/api/session/session-api.errors';

export type LoginMode = 'login' | 'switch' | 'timeout';

@UntilDestroy()
@Component({
  selector: 'rais-login-modal',
  templateUrl: './login-modal.component.html',
  styleUrls: ['./login-modal.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginModalComponent implements OnDestroy {
  private modalData = inject<{ submittedCallback: (user: User) => void; loginMode: LoginMode }>(NZ_MODAL_DATA);

  form: UntypedFormGroup;
  isLoggingIn = signal(false);

  static LoginModalOpened: boolean;

  constructor(
    fb: UntypedFormBuilder,
    private nzModalRef: NzModalRef,
    private sessionQuery: SessionQuery,
    private sessionService: SessionService,
    private nzNotificationService: NzNotificationService,
  ) {
    this.form = fb.group({
      login: ['', Validators.required],
      password: ['', Validators.required],
    });

    LoginModalComponent.LoginModalOpened = true;
  }

  onSubmit() {
    this.isLoggingIn.set(true);
    this.sessionService
      .auth({
        ...this.form.value,
      })
      .pipe(finalize(() => this.isLoggingIn.set(false)))
      .subscribe({
        next: (session) => {
          this.modalData.submittedCallback(session.user);
          this.nzModalRef.close();
        },
        error: (error) => {
          if (error instanceof LoginFailedError) {
            this.nzNotificationService.error('Вход в систему', error.message);
            return;
          }

          throw error;
        },
      });
  }

  ngOnDestroy(): void {
    LoginModalComponent.LoginModalOpened = false;
  }

}
