import { inject, Injectable } from '@angular/core';
import { SessionStore } from './session.store';
import { finalize, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { User } from '../models/user';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { NzModalService } from 'ng-zorro-antd/modal';
import { LoginModalComponent, LoginMode } from '@core/session/components/login-modal/login-modal.component';
import { UserModalComponent } from '@core/session/components/user-modal/user-modal.component';
import { SessionApiService } from '@backend/api/session/session-api.service';

@Injectable({ providedIn: 'root' })
export class SessionService {
  private readonly sessionApi = inject(SessionApiService);

  constructor(
    private sessionStore: SessionStore,
    private http: HttpClient,
    private router: Router,
    private nzModalService: NzModalService,
  ) {}

  auth(request: { login: string; password: string }) {
    return this.sessionApi.login(request.login, request.password).pipe(
      tap((result) => {
        this.sessionStore.update({ user: result.user, session: result });
      }),
    );
  }

  tryRestore() {
    return this.sessionApi.getCurrent().pipe(
      tap((result) => {
        this.sessionStore.update({ user: result.user, session: result });
      }),
    );
  }

  setTimeoutEnabled(timeoutEnabled: boolean) {
    this.sessionStore.update({
      timeoutEnabled,
    });
  }

  setCurrentDomain(currentDomain: number) {
    this.sessionStore.update({
      currentDomain,
    });
  }

  addObservableProcess(processId: number): Observable<User> {
    return this.http.put(`user/observable-processes/${processId}`, {}).pipe(
      tap((user: any) => {
        this.sessionStore.update({
          user: {
            ...this.sessionStore.getValue().user,
            observableProcessesIds: user.observableProcessesIds,
          },
        });
      }),
    );
  }

  deleteObservableProcess(processId: number): Observable<User> {
    return this.http.delete(`user/observable-processes/${processId}`).pipe(
      tap((user: any) => {
        this.sessionStore.update({
          user: {
            ...this.sessionStore.getValue().user,
            observableProcessesIds: user.observableProcessesIds,
          },
        });
      }),
    );
  }

  logout() {
    return this.sessionApi.logout().pipe(
      finalize(() => {
        this.sessionStore.update({
          user: null,
          session: null,
        });
        this.openLoginModal('login');
        this.router.navigate(['/']);
      }),
    );
  }

  openLoginModal(loginMode: LoginMode): Promise<User> {
    if (loginMode == 'timeout') {
      this.sessionStore.update({
        timeoutEnabled: false,
      });
      localStorage.removeItem('session:basicCreds');
    }
    if (!LoginModalComponent.LoginModalOpened) {
      let options = {
        nzWidth: 670,
        nzClosable: true,
        nzKeyboard: true,
        nzMaskClosable: false
      };

      if (loginMode == 'timeout' || loginMode == 'login') {
        options.nzClosable = false;
        options.nzKeyboard = false;
      }

      return new Promise((resolve) => {
        this.nzModalService.create({
          nzTitle: 'Авторизация',
          nzContent: LoginModalComponent,
          nzData: {
            submittedCallback: (user: User) => resolve(user),
            loginMode,
          },
          ...options
        });
      });
    }
  }

  openUserModal() {
    this.nzModalService.create({
      nzTitle: 'Текущий пользователь',
      nzContent: UserModalComponent,
    });
  }

  upsert(user: User) {
    this.sessionStore.update({
      user,
    });
  }

  toggleDebugMode(state?: boolean) {
    this.sessionStore.update({
      debugMode: state === true || state === false ? state : !this.sessionStore.getValue().debugMode,
    });
  }
}
